import { MayBeNull } from '@wpp-open/core'

import { ProjectMember } from 'types/projects/projectMembers'
import { TaskStatus } from 'types/projects/tasks'

export interface TimelineMember extends ProjectMember {
  avatarUrl: MayBeNull<string>

  wrikeExternalFirstName: MayBeNull<string>
  wrikeExternalLastName: MayBeNull<string>
  wrikeIntegrationStatus: MayBeNull<string>
  wrikeEmail: MayBeNull<string>
  wrikeConnectedAt: MayBeNull<string>
  wrikeInvitationSentAt: MayBeNull<string>
}

export interface TimelineTask {
  id: string
  name: string
  createdAt: string
  startDate: MayBeNull<string>
  endDate: MayBeNull<string>
  status: MayBeNull<TaskStatus>
  assignee: MayBeNull<TimelineMember>
  subTasks: MayBeNull<TimelineTask[]> // task, that represented the app-in-activity
}

export type ExternalTask = TimelineTask

export interface TimelinePhase {
  id: string
  name: string
  startDate: MayBeNull<string>
  endDate: MayBeNull<string>
  status: MayBeNull<TaskStatus>
  assignee: MayBeNull<TimelineMember>
  tasks: MayBeNull<TimelineTask[]>
}

export interface LinearTimeline {
  projectId: string
  phases: TimelinePhase[]
  externalTasks: ExternalTask[]
}

export interface FluidTimeline {
  projectId: string
  tasks: TimelineTask[]
}

export type Timeline = FluidTimeline | LinearTimeline

export const isFluidTimeline = (timeline: Timeline): timeline is FluidTimeline => 'tasks' in timeline

export const isPhase = (timelineItem: TimelinePhase | TimelineTask): timelineItem is TimelinePhase =>
  'tasks' in timelineItem
