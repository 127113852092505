import { TenantType } from '@wpp-open/core'
import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { DefaultHierarchyLevelType } from '@wpp-open/core/types/tenant'
import { useOs } from '@wpp-open/react'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { HierarchyTreeNode } from 'api/hubs/fetchers/getTenantHierarchy'
import { useGetHubsInfo } from 'api/hubs/queries/useGetHubsInfo'
import { useGetTenantHierarchy } from 'api/hubs/queries/useGetTenantHierarchy'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useHierarchyFilters } from 'pages/dashboard/utils/hierarchyFilters'
import { LocalStorageKey } from 'types/common/localStorage'
import { ProjectOwnership, ProjectStatus } from 'types/projects/projects'
import { TaskDueDate } from 'types/projects/tasks'

interface Props<T extends Record<string, any>> {
  initState: T
  lsCode: LocalStorageKey.DASHBOARD_FILTERS | LocalStorageKey.DASHBOARD_TASKS_FILTERS
}

const getNodeType = (node: HierarchyTreeNode) =>
  (node?.type === HierarchyCustomNodeType ? node.customTypeName : node?.type)?.toLocaleLowerCase() as string

const validateLSFilters = <T extends Record<string, any>>(filters: T): T => {
  if (!filters.status?.every((status: string) => status in ProjectStatus)) {
    return {} as T
  }

  return filters
}

export const useFilters = <T extends Record<string, any>>({
  initState,
  lsCode,
}: Props<T>): {
  isLoading: boolean
  state: T
} => {
  const {
    osContext: { userDetails, tenant, navigationTree },
  } = useOs()

  // For client type we will have only 1 client in hierarchy, so we need to pick it for preselect
  const isClientTenant = tenant.tenantType === TenantType.Client
  const preselectClients = Object.values(navigationTree.mapping).filter(
    el => el.type === DefaultHierarchyLevelType.Client,
  )

  const hubIdRef = useRef<URLSearchParams | null>(null)
  const [resetFilter, setResetFilter] = useState<boolean | null>(null)
  const [params, setParams] = useSearchParams()
  const isAgencyWorkspace = tenant.tenantType === TenantType.Agency

  const [rawFilters] = useLocalStorage<T>(`${lsCode}:${userDetails.id}`, {} as T)
  const lsFiltersState = useMemo(() => validateLSFilters<T>(rawFilters), [rawFilters])

  useLayoutEffect(() => {
    setResetFilter(params.get('resetFilters')?.toLowerCase() === 'true')

    if (params.get('hubId') && !params.get('resetFilters')) {
      hubIdRef.current = params
    }

    setParams()
  }, [params, setParams])

  const { options, getAllChildren } = useHierarchyFilters()

  const { data: hub, isLoading: isHubLoading } = useGetHubsInfo({
    params: { hubId: hubIdRef.current?.get('hubId')! },
    enabled: !!hubIdRef.current?.get('hubId') && !isAgencyWorkspace,
  })

  const { data: hierarchyTree, isLoading: isHierarchyLoading } = useGetTenantHierarchy({
    params: { tenantId: hub?.tenantId! },
    enabled: !!hub && !isAgencyWorkspace,
  })

  const hubFilters = useMemo(() => {
    if (isAgencyWorkspace) return {}

    const node = hierarchyTree?.mapping[HierarchyContainerNodeId]
    const children = node?.children || []

    if (!hub && !hierarchyTree?.mapping) return null
    const rootNode = hierarchyTree?.mapping[hub?.organizationUnitId!]

    if (rootNode && rootNode.azId === hierarchyTree.rootId) return null

    const list = Object.values(hierarchyTree?.mapping || {})
    const parens: HierarchyTreeNode[] = []

    let currentId = rootNode?.azId
    while (currentId) {
      // eslint-disable-next-line no-loop-func
      const parent = list.find(node => node.children.includes(currentId!))
      if (!parent || !parent.azId) break
      parens.push(parent)
      currentId = parent.azId
    }

    // get filters for Workspace
    const allOptionsToSelect = [...parens, rootNode].reduce((acc, node) => {
      const nodeType = getNodeType(node!)
      const optionToSelect = options[nodeType]?.find(option => option.value.includes(node?.azId!))
      return { ...acc, [nodeType]: [optionToSelect?.value] }
    }, {} as Partial<T>)
    const workspace = getAllChildren(allOptionsToSelect, children)

    // All other filters
    const { hubId, ...rest } = Object.fromEntries(hubIdRef.current!) as { hubId: string } & T
    const allOtherFilters = Object.keys(rest).reduce((acc, key) => {
      if (key in initState) {
        // For projects filter
        if (key === 'ownership' && !(rest[key] in ProjectOwnership)) return acc

        if (key === 'status') {
          acc[key] = [...hubIdRef.current!.getAll(key).filter((status: string) => status in ProjectStatus)]
          return acc
        }

        if (key === 'type') {
          acc[key] = [...hubIdRef.current!.getAll(key)]
          return acc
        }

        // For tasks filter
        if (key === 'selectedProjects') {
          acc[key] = hubIdRef.current!.getAll(key)
          return acc
        }
        if (key === 'dueDateRanges') {
          acc[key] = [...hubIdRef.current!.getAll(key).filter((range: string) => range in TaskDueDate)]
          return acc
        }
        acc[key] = rest[key]
      }

      return acc
    }, {} as Record<string, any>)

    return { workspace, ...allOtherFilters, ...allOptionsToSelect }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub, hierarchyTree])

  return useMemo(
    () => {
      const isLsFiltersEmpty = Object.keys(lsFiltersState).length === 0
      const localstorageFilters =
        isClientTenant && preselectClients.length === 1 && isLsFiltersEmpty
          ? {
              [DefaultHierarchyLevelType.Client.toLowerCase()]: [preselectClients[0].azId!],
              workspace: [preselectClients[0].azId!],
            }
          : lsFiltersState

      return {
        isLoading: (!!hubIdRef.current?.get('hubId') && (isHubLoading || isHierarchyLoading)) || resetFilter === null,
        state: resetFilter
          ? initState
          : {
              ...initState,
              ...(!!hubIdRef.current ? hubFilters : localstorageFilters),
            },
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hubFilters, isHierarchyLoading, isHubLoading, lsFiltersState, resetFilter],
  )
}
