import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Task as GanttTask } from 'gantt-task-react'
import React, { useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/timeline/components/taskListTable/TaskListTable.module.scss'
import { TimelineDateInline } from 'pages/project/components/timeline/components/taskListTable/TimelineDateInline'
import { highlightRow, unHighlightRow } from 'pages/project/components/timeline/components/taskListTable/utils'
import { TimelineContext } from 'pages/project/components/timeline/TimelineProvider'
import { DetailsModalType } from 'types/common/utils'
import { TimelinePhase, TimelineTask } from 'types/projects/timeline'
import { fullName } from 'utils/common'

interface Props {
  rowHeight: number
  rowWidth: string
  fontFamily: string
  fontSize: string
  locale: string
  tasks: GanttTask[]
  selectedTaskId: string
  setSelectedTask: (taskId: string) => void
  onExpanderClick: (task: GanttTask) => void
}
export const TaskListTable = ({
  rowHeight,
  rowWidth,
  tasks: ganttTasks,
  fontFamily,
  fontSize,
  onExpanderClick,
}: Props) => {
  const { project } = useProject()
  const { allPhases, allTasks } = useContext(TimelineContext)
  const [, setSearchParams] = useSearchParams()

  const timelineTasks = useMemo(() => {
    const allTasksMap = Object.fromEntries(allTasks.map(task => [task.id, task]))
    const allPhasesMap = Object.fromEntries(allPhases.map(task => [task.id, task]))

    return ganttTasks.reduce<Record<string, TimelineTask | TimelinePhase>>((acc, task) => {
      const timelineTask = allTasksMap[task.id] || allPhasesMap[task.id]
      if (timelineTask) {
        acc[timelineTask.id] = timelineTask
      }
      return acc
    }, {})
  }, [allPhases, allTasks, ganttTasks])

  const handleTaskOpen = (task: GanttTask) => {
    if (task.type === 'project') {
      setSearchParams({ view: DetailsModalType.PHASE_DETAILS_PREVIEW, phaseId: task.id })
      return
    }

    setSearchParams({ view: DetailsModalType.TASK_DETAILS_PREVIEW, id: task.id })
  }

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {ganttTasks.map((task, idx) => {
        const timelineTask = timelineTasks[task.id]
        let expanderSymbol = ''

        if (!timelineTask) {
          return null
        }

        if ('tasks' in timelineTask && (timelineTask.tasks?.length ?? 0) > 0) {
          expanderSymbol = task.hideChildren === false ? '▼' : '▶'
        }

        return (
          <div
            className={styles.taskListTableRow}
            style={{ height: rowHeight }}
            key={`${task.id}row`}
            onMouseEnter={() => highlightRow(idx)}
            onMouseLeave={() => unHighlightRow(idx)}
          >
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
              title={timelineTask.name}
            >
              <div className={styles.taskListNameWrapper}>
                <div
                  className={expanderSymbol ? styles.taskListExpander : styles.taskListEmptyExpander}
                  onClick={() => onExpanderClick(task)}
                >
                  {expanderSymbol}
                </div>
                <WppTypography
                  type={task.type === 'project' ? 's-strong' : 's-body'}
                  className={styles.name}
                  onClick={() => handleTaskOpen(task)}
                >
                  {timelineTask.name}
                </WppTypography>

                <div className={styles.actions}>
                  {timelineTask.assignee && (
                    <Avatar
                      className={styles.avatar}
                      size="xs"
                      src={timelineTask.assignee.avatarUrl!}
                      withTooltip
                      name={fullName(
                        timelineTask.assignee.firstname || timelineTask.assignee.wrikeExternalFirstName!,
                        timelineTask.assignee.lastname || timelineTask.assignee.wrikeExternalLastName!,
                      )}
                    />
                  )}
                  {!timelineTask.startDate && <TimelineDateInline project={project} timelineItem={timelineTask} />}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
