import {
  WppCardGroup,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'

import { useField } from 'hooks/form/useField'
import styles from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType.module.scss'
import { ProcessType } from 'types/projects/projects'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppCardGroup>, 'onChange' | 'onWppChange' | 'value'> {
  name: string
  templateId?: string
  resetTemplateId?: () => void
  disableFluid?: boolean
}

export const SelectProcessType = forwardRef<HTMLWppSegmentedControlElement, Props>(
  ({ name, templateId, resetTemplateId, disableFluid }, ref) => {
    const innerRef = useRef<HTMLWppSegmentedControlElement>(null)
    const { t } = useTranslation()

    const {
      field: { value, onChange },
    } = useField({ name })

    return (
      <WppSegmentedControl
        width="100%"
        ref={mergeRefs([innerRef, ref])}
        size="m"
        value={value}
        onWppChange={event => {
          onChange(event.detail.value)
          if (templateId) {
            resetTemplateId?.()
          }
        }}
        data-testid="process-type-select"
      >
        <WppSegmentedControlItem value={ProcessType.LINEAR}>
          <WppTooltip
            text={t('modals.create_project.field_process_type_linear_tooltip')!}
            className={styles.segmentTooltip}
          >
            {t(`overview.process_type_${ProcessType.LINEAR}`)}
          </WppTooltip>
        </WppSegmentedControlItem>
        <WppSegmentedControlItem value={ProcessType.FLUID} disabled={disableFluid}>
          <WppTooltip
            text={
              disableFluid
                ? t('modals.create_project.field_process_type_fluid_tooltip_soon')!
                : t('modals.create_project.field_process_type_fluid_tooltip')!
            }
            className={styles.segmentTooltip}
          >
            {t(`overview.process_type_${ProcessType.FLUID}`)}
          </WppTooltip>
        </WppSegmentedControlItem>
      </WppSegmentedControl>
    )
  },
)
