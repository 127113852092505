import { MayBeNull } from '@wpp-open/core'
import { endOfDay, isPast, startOfDay } from 'date-fns'
import { Task as GanttTask } from 'gantt-task-react'

import { TaskDueDate, TaskStatus } from 'types/projects/tasks'
import { isFluidTimeline, Timeline, TimelineTask } from 'types/projects/timeline'

const Colors = {
  default: 'var(--wpp-primary-color-100)',
  complete: 'var( --wpp-dataviz-color-seq-positive-100)',
  overdue: 'var(--wpp-dataviz-color-seq-negative-100)',
}

export interface TimelineFilters {
  search?: string
  dueDateRanges?: (keyof typeof TaskDueDate)[]
  selectedStatuses?: string[]
}

export const initialTimelineFilters: TimelineFilters = {
  search: undefined,
  dueDateRanges: [],
  selectedStatuses: [],
}

export const timeline2Gantt = (timeline: MayBeNull<Timeline>): GanttTask[] => {
  if (!timeline) {
    return []
  }

  if (isFluidTimeline(timeline)) {
    return timeline.tasks.map((task): GanttTask => createGanttTask(task))
  }

  const ganttTasks = timeline.phases.reduce<GanttTask[]>((acc, phase) => {
    const isOverdue = phase.endDate && isPast(endOfDay(new Date(phase.endDate)))
    const color = phase.status === TaskStatus.COMPLETED ? Colors.complete : isOverdue ? Colors.overdue : Colors.default

    // project own start date or the earliest task start date
    const startDate = phase.startDate
      ? phase.startDate
      : phase.tasks
          ?.filter(({ startDate }) => !!startDate)
          .map(({ startDate }) => startDate)
          .sort((a, b) => new Date(a!).getTime() - new Date(b!).getTime())[0]

    // project own end date or the latest task start date
    const endDate = phase.endDate
      ? phase.endDate
      : phase.tasks
          ?.filter(({ endDate }) => !!endDate)
          .map(({ endDate }) => endDate)
          .sort((a, b) => new Date(b!).getTime() - new Date(a!).getTime())[0]

    const isNoDates = !startDate || !endDate

    const project: GanttTask = {
      id: phase.id,
      name: '',
      start: !isNoDates ? startOfDay(new Date(startDate!)) : new Date(),
      end: !isNoDates ? endOfDay(new Date(endDate!)) : new Date(),

      type: 'project',
      progress: 100,
      hideChildren: true,
      isDisabled: true,
      styles: {
        backgroundColor: isNoDates ? 'transparent' : color,
        backgroundSelectedColor: isNoDates ? 'transparent' : color,
        progressColor: isNoDates ? 'transparent' : color,
        progressSelectedColor: isNoDates ? 'transparent' : color,
      },
    }

    return [...acc, project, ...phase.tasks?.map(task => createGanttTask(task, project.id))!]
  }, [] as GanttTask[])

  return ganttTasks.concat(timeline.externalTasks.map(task => createGanttTask(task)))
}

function createGanttTask(task: TimelineTask, projectId?: string): GanttTask {
  const isNoDates = !task.startDate || !task.endDate
  const isTaskOverdue = task.endDate && isPast(endOfDay(new Date(task.endDate)))
  const taskColor =
    task.status === TaskStatus.COMPLETED ? Colors.complete : isTaskOverdue ? Colors.overdue : Colors.default

  return {
    id: task.id,
    project: projectId,
    name: '',
    start: !isNoDates ? startOfDay(new Date(task.startDate!)) : new Date(),
    end: !isNoDates ? endOfDay(new Date(task.endDate!)) : new Date(),

    type: 'task',
    progress: 0,
    isDisabled: isNoDates,

    styles: {
      backgroundColor: isNoDates ? 'transparent' : taskColor,
      backgroundSelectedColor: isNoDates ? 'transparent' : taskColor,
      progressColor: isNoDates ? 'transparent' : taskColor,
      progressSelectedColor: isNoDates ? 'transparent' : taskColor,
    },
  }
}
