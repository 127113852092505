import { createContext, PropsWithChildren } from 'react'

import { useProject } from 'hooks/useProject'
import { ProcessType } from 'types/projects/projects'
import { isFluidTimeline, Timeline, TimelinePhase, TimelineTask } from 'types/projects/timeline'

interface TimelineContextProps {
  processType: ProcessType
  timeline: Timeline
  allPhases: TimelinePhase[]
  allTasks: TimelineTask[]
}

export const TimelineContext = createContext<TimelineContextProps>(null!)

export const TimelineProvider = ({ children, timeline }: PropsWithChildren<{ timeline: Timeline }>) => {
  const {
    project: { processType },
  } = useProject()

  const allPhases = isFluidTimeline(timeline) ? [] : timeline.phases

  const allTasks = isFluidTimeline(timeline)
    ? timeline.tasks
    : [...timeline.phases.flatMap(phase => phase.tasks ?? []), ...timeline.externalTasks]

  return (
    <TimelineContext.Provider
      value={{
        processType,
        timeline,
        allPhases,
        allTasks,
      }}
    >
      {children}
    </TimelineContext.Provider>
  )
}
