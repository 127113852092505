import {
  WppIconCalendar3Days,
  WppIconFile,
  WppIconPeople,
  WppIconVideoClip,
  WppTag,
} from '@platform-ui-kit/components-library-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ProjectType } from 'types/projects/projects'

interface Props {
  type: ProjectType
  className?: string
}

interface ProjectTypePreset {
  value: ProjectType
  icon: JSX.Element
  title: string
  colorIndex: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | undefined
}

const projectTypePreset: Record<ProjectType, ProjectTypePreset> = {
  [ProjectType.MISCELLANEOUS]: {
    value: ProjectType.MISCELLANEOUS,
    icon: <WppIconFile color="var(--wpp-dataviz-color-cat-dark-1)" slot="icon-start" />,
    title: 'project.list.tag_project_type_miscellaneous',
    colorIndex: 1,
  },
  [ProjectType.PITCH]: {
    value: ProjectType.PITCH,
    icon: <WppIconVideoClip color="var(--wpp-dataviz-color-cat-dark-3)" slot="icon-start" />,
    title: 'project.list.tag_project_type_pitch',
    colorIndex: 4,
  },
  [ProjectType.WORKSHOP]: {
    value: ProjectType.WORKSHOP,
    icon: <WppIconPeople color="var(--wpp-dataviz-color-cat-dark-5)" slot="icon-start" />,
    title: 'project.list.tag_project_type_workshop',
    colorIndex: 5,
  },
  [ProjectType.CAMPAIGN]: {
    value: ProjectType.CAMPAIGN,
    icon: <WppIconCalendar3Days color="var(--wpp-dataviz-color-cat-dark-7)" slot="icon-start" />,
    title: 'project.list.tag_project_type_campaign',
    colorIndex: 7,
  },
}

export const ProjectTypeTag: FC<Props> = ({ type, className }) => {
  const { t } = useTranslation()
  const { title } = projectTypePreset[type]

  return (
    <Flex inline align="center" gap={6} className={className}>
      <WppTag variant="neutral" label={t(title)!} data-testid="project-type" />
    </Flex>
  )
}
