import { NavigationTreeNode } from '@wpp-open/core'
import { HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { getChildHierarchyLevels } from '@wpp-open/core/utils/hierarchy'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'

export const useHierarchy = () => {
  const {
    osContext: { tenant, navigationTree },
  } = useOs()

  const NAVIGATION_TREE = useMemo(() => Object.values(navigationTree.mapping), [navigationTree.mapping])

  const hierarchyOrder = useMemo(() => getChildHierarchyLevels(tenant).map(({ type }) => type.toLowerCase()), [tenant])

  const getWorkspaceTree = useCallback(
    (fromAzId: string = '') => {
      if (!fromAzId || !Object.keys(navigationTree.mapping).includes(fromAzId)) return []

      const tree: NavigationTreeNode[] = []
      const childHierarchy = getChildHierarchyLevels(tenant)

      const lastNode = navigationTree.mapping[fromAzId]
      const fromIndex = childHierarchy.findIndex(
        el => el.type === (lastNode.type === HierarchyCustomNodeType ? lastNode.customTypeName : lastNode.type),
      )
      tree[fromIndex] = lastNode

      for (let i = fromIndex - 1; i >= 0; i--) {
        const parentNode = NAVIGATION_TREE.find(({ children }) => children?.includes(tree[i + 1].azId!))

        if (!parentNode) return []

        tree[i] = parentNode
      }

      return tree
    },
    [NAVIGATION_TREE, navigationTree.mapping, tenant],
  )

  const mapEntitiesText = (entities: NavigationTreeNode[]): string => {
    // Sort entities according to the hierarchyOrder
    const sortedEntities = entities.sort((a, b) => {
      const aIndex = hierarchyOrder.indexOf(a.type.toLowerCase())
      const bIndex = hierarchyOrder.indexOf(b.type.toLowerCase())
      return aIndex - bIndex
    })

    // Map sorted entities to the desired format
    return sortedEntities
      .map(entity => {
        let entityType = entity.type.toLowerCase()
        // Capitalize the first letter of the type
        entityType = entityType.charAt(0).toUpperCase() + entityType.slice(1)
        return `${entityType}: ${entity.name}`
      })
      .join(' / ')
  }

  return { hierarchyOrder, getWorkspaceTree, mapEntitiesText }
}
