import { useOs } from '@wpp-open/react'
import { forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { LinearActivity } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/LinearActivity'
import { LinearApplication } from 'pages/project/components/canvas/linearCanvas/components/item/linearApplication/LinearApplication'
import { isActivityItem, isApplicationItem } from 'pages/project/components/canvas/phaseUtils'
import { showConfirmExternalModal } from 'pages/project/components/members/components/confirmIExternalModal/ConfirmExternalModal'
import { ProjectMember } from 'types/projects/projectMembers'
import { PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  phaseItem: PhaseItem
  index: number
  isIAssignToThisPhase?: boolean
  isEditable?: boolean
  toggleEditModal?: () => void
  variant?: 'primary' | 'secondary'
  preview?: boolean
  isDraggingDisabled?: boolean
  projectId: string
  isWrikeConnected?: boolean
  isTemplate?: boolean
  isOwnerOrGlobalManage?: boolean
}

export const Item = forwardRef<HTMLDivElement, Props>(
  (
    {
      phaseItem,
      index,
      isIAssignToThisPhase,
      isEditable,
      variant,
      toggleEditModal,
      preview,
      isDraggingDisabled,
      projectId,
      isWrikeConnected,
      isTemplate,
      isOwnerOrGlobalManage,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const {
      osContext: { userDetails },
    } = useOs()
    const { useExternalStatuses } = useProject() ?? {}

    const assignMember = useAssignMember(phaseItem.item.assignUser, projectId)

    const isMeAssignToThisApp = useMemo(
      () => isEqualEmails(userDetails.email, assignMember?.email),
      [userDetails, assignMember],
    )

    const showAction = !!isEditable && (isOwnerOrGlobalManage || isIAssignToThisPhase || isMeAssignToThisApp)

    const isDisabled = !!isEditable && !showAction

    const { updateItem } = useUpdateItem({
      id: phaseItem.item.id,
      type: phaseItem.itemType,
      name: phaseItem.item.name,
      projectId,
      useExternalStatuses,
    })

    const toggleAssignee = async (newAssignee: ResponsibleUser) => {
      const isDeselecting = assignMember?.id === newAssignee.id

      if (isDeselecting || !newAssignee.isExternal) {
        await updateItem({ assignUser: isDeselecting ? null : newAssignee })
      } else {
        showConfirmExternalModal({
          title: t('modals.invite_member.assign_external_title'),
          description: t('modals.invite_member.assign_external_description', {
            member: `${newAssignee.firstname} ${newAssignee.lastname}`,
            item: phaseItem.item.name,
          }),
          withDelete: false,
          members: [newAssignee as unknown as ProjectMember],
          inviteMembers: async ids => {
            if (!!ids.length) {
              await updateItem({ assignUser: isDeselecting ? null : newAssignee })
            }
          },
        })
      }
    }

    return (
      <div
        ref={ref}
        data-testid={`phase-item-card-${phaseItem.item.id}`}
        itemType={isApplicationItem(phaseItem) ? 'application' : 'activity'}
      >
        {isApplicationItem(phaseItem) && (
          <LinearApplication
            phaseId={phaseItem.phaseId}
            application={phaseItem.item}
            isEditable={isEditable}
            isDisabled={isDisabled}
            showAction={showAction}
            variant={variant}
            index={index}
            editApp={toggleEditModal}
            changeAssignee={toggleAssignee}
            changeDates={dates => updateItem({ dates })}
            changeStatus={status => updateItem({ status })}
            changeHidden={hidden => updateItem({ hidden })}
            preview={preview}
            isDraggingDisabled={isDraggingDisabled}
            projectId={projectId}
            isWrikeConnected={isWrikeConnected}
            isTemplate={isTemplate}
          />
        )}
        {isActivityItem(phaseItem) && (
          <LinearActivity
            activity={phaseItem.item}
            phaseItemId={phaseItem.id}
            isDisabled={isDisabled}
            isEditable={!!isEditable}
            showAction={showAction}
            isIAssignToThisPhase={isIAssignToThisPhase}
            variant={variant}
            index={index}
            changeAssignee={toggleAssignee}
            changeDates={dates => updateItem({ dates })}
            changeStatus={status => updateItem({ status })}
            editActivity={toggleEditModal}
            phaseId={phaseItem.phaseId}
            preview={preview}
            isDraggingDisabled={isDraggingDisabled}
            projectId={projectId}
            isWrikeConnected={isWrikeConnected}
            isTemplate={isTemplate}
          />
        )}
      </div>
    )
  },
)
