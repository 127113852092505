import { WppIconCalendarError } from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'

import { projectDateFormat } from 'pages/components/projectModal/utils'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { getDate } from 'pages/project/components/canvas/components/selectPerson/utils'
import { useUpdatePhase } from 'pages/project/components/canvas/hooks/useUpdatePhase'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useUpdateTaskItem } from 'pages/project/components/tasks/hooks/useUpdateTaskItem'
import { Project } from 'types/projects/projects'
import { isPhase, TimelinePhase, TimelineTask } from 'types/projects/timeline'

interface Props {
  project: Project
  timelineItem: TimelineTask | TimelinePhase
}

const CalendarIcon = () => <WppIconCalendarError color="var(--wpp-danger-color-500)" />

export const TimelineDateInline = ({ project, timelineItem }: Props) => {
  const { updatePhase } = useUpdatePhase({ phaseId: timelineItem.id, projectId: project.id })
  const { updateItem } = useUpdateTaskItem({ filters: { projectId: project.id } })

  const updateTaskDates = async (dates: Date[]) => {
    const stringDates = dates.map(date => format(date, projectDateFormat))

    const datesMapped = { ...getDate(stringDates) }

    await updateItem({ id: timelineItem.id, ...datesMapped })
    await invalidateCanvas()
  }

  const handleDatesChange = (dates: Date[]) => {
    if (isPhase(timelineItem)) {
      updatePhase({ dates, name: timelineItem.name })
      return
    }

    updateTaskDates(dates)
  }

  return (
    <SelectDateInline
      calendarIcon={CalendarIcon}
      startDate={timelineItem.startDate!}
      endDate={timelineItem.endDate!}
      onChange={handleDatesChange}
    />
  )
}
