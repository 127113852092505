import { AnalyticsActionType } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useCallback, useEffect } from 'react'

import { ACTION_ANALYTICS, PAGE_ANALYTICS } from 'constants/analytics'
import { isDevelop, noop } from 'utils/common'

export const useTrackAction = () => {
  const { osApi } = useOs()
  const { analytics } = osApi

  const trackAction = useCallback(
    (action: ACTION_ANALYTICS, payload: Record<string, any>) => {
      isDevelop
        ? noop()
        : analytics.track({
            type: AnalyticsActionType.action,
            payload: {
              action,
              params: Object.entries(payload).map(([key, value]) => ({ key, value })),
            },
          })
    },
    [analytics],
  )

  return { trackAction }
}

export const useTrackPage = (payload: PAGE_ANALYTICS) => {
  const { osApi } = useOs()
  const { analytics } = osApi

  useEffect(() => {
    isDevelop
      ? noop()
      : analytics.track({
          type: AnalyticsActionType.page,
          payload,
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
