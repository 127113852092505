import { useProject } from 'hooks/useProject'
import { FluidTab } from 'pages/project/components/canvas/fluidCanvas/FluidTab'
import { LinearProvider } from 'pages/project/components/canvas/linearCanvas/LinearProvider'
import { LinearTab } from 'pages/project/components/canvas/linearCanvas/LinearTab'
import { ProcessType } from 'types/projects/projects'

export const CanvasTab = () => {
  const { project } = useProject()

  return project.processType === ProcessType.LINEAR ? (
    <LinearProvider>
      <LinearTab />
    </LinearProvider>
  ) : (
    <FluidTab />
  )
}
