import { SelectChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppSelectCustomEvent } from '@platform-ui-kit/components-library/loader'
import {
  WppButton,
  WppFilterButton,
  WppIconDataViewCards,
  WppIconDataViewList,
  WppIconPlus,
  WppInput,
  WppSegmentedControl,
  WppSegmentedControlItem,
  WppSpinner,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { useSessionStorage } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { PageBackToTop } from 'components/common/table/PageBackToTop'
import { ACTION_ANALYTICS } from 'constants/analytics'
import { useTrackAction } from 'hooks/useAnalytics'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useFilters } from 'hooks/useFilters'
import { useHierarchy } from 'hooks/useHierarchy'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { showCreateProjectModal } from 'pages/components/projectModal/CreateProjectModal'
import { DashboardNavigation } from 'pages/dashboard/components/dashboardNavigation/DashboardNavigation'
import { OnRouteEnter } from 'pages/dashboard/components/onRouteEnter/OnRouteEnter'
import { showProjectFilterModal } from 'pages/dashboard/components/projectFilterModal/ProjectFilterModal'
import { ProjectCardView } from 'pages/dashboard/components/projectsCardView/ProjectCardView'
import { ProjectsTableView } from 'pages/dashboard/components/projectsTableView/ProjectsTableView'
import { TenantFilterSelect } from 'pages/dashboard/components/tenantFilterSelect/TenantFilterSelect'
import styles from 'pages/dashboard/Dashboard.module.scss'
import { useIsFiltersActive } from 'pages/dashboard/utils/useIsFiltersActive'
import { ViewType } from 'pages/dashboard/utils/useSessionViewType'
import { LocalStorageKey } from 'types/common/localStorage'
import { AppPermissions } from 'types/permissions/permissions'
import { ProjectFilter, ProjectOwnership, ProjectStatus } from 'types/projects/projects'
import { routesManager } from 'utils/routesManager'

const filtersCount = ({ status, type, ownership, search, workspace, tenant, ...hierarchy }: ProjectFilter) => {
  const restCount = Object.values(hierarchy).reduce((acc, curr) => acc + (curr?.length || 0), 0)

  return (status as any[]).concat(type, ownership).length + restCount
}

export const initialProjectFilters: ProjectFilter = {
  status: [ProjectStatus.TO_DO, ProjectStatus.IN_PROGRESS],
  tenant: [],
  type: [],
  search: undefined,
  ownership: ProjectOwnership.ALL,
}

export const DashboardPage = () => {
  const { isLoading: isFiltersLoading, state: filtersState } = useFilters({
    initState: initialProjectFilters,
    lsCode: LocalStorageKey.DASHBOARD_FILTERS,
  })

  console.log({ isFiltersLoading, filtersState })

  if (isFiltersLoading)
    return (
      <Flex justify="center" align="center" className={styles.spinner}>
        <WppSpinner size="l" />
      </Flex>
    )

  return <Dashboard filtersState={filtersState} />
}

const Dashboard = ({ filtersState }: { filtersState?: ProjectFilter }) => {
  const { t } = useTranslation()
  const {
    osContext: { userDetails },
  } = useOs()

  const { trackAction } = useTrackAction()
  const { isPermitted } = useIsPermitted()

  const [projectsExist, setProjectsExist] = useState<boolean>(false)
  const [, setHideSubHeader] = useState(false)

  const [viewType, setViewType] = useSessionStorage('WPP_OPEN_PROJECTS_VIEW', ViewType.CARDS)

  const canCreateProject =
    isPermitted(AppPermissions.ORCHESTRATION_PROJECTS_CREATE) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const { hierarchyOrder } = useHierarchy()

  const [filter, setFilters] = useState<ProjectFilter>({
    ...initialProjectFilters,
    ...hierarchyOrder.reduce(
      (pre, curr) => ({
        ...pre,
        [curr]: [],
      }),
      {},
    ),
    ...filtersState,
  })

  useEffect(() => {
    const { search, ...rest } = filter
    localStorage.setItem(`ds:${userDetails.id}`, JSON.stringify(rest))
  }, [filter, userDetails.id])

  const filtersCounts = useMemo(() => filtersCount(filter), [filter])

  const isFilterActive = useIsFiltersActive(filter)

  useEffect(() => trackAction(ACTION_ANALYTICS.ACTION_PAGE_PROJECTS, filter), [trackAction, filter])

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= 2 ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, 300)

  const handleCloseFilterModal = (newFilter: ProjectFilter) => {
    setFilters({ ...filter, ...newFilter })
  }

  const handleWorkspaceFilterChange = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setFilters(filters => ({ ...filters, tenant: e.detail.value }))
  }

  return (
    <Flex direction="column" className={styles.container}>
      <PageBackToTop scrollTopOffset={120} onChangeState={setHideSubHeader} />
      <DashboardNavigation />

      <div
        className={clsx(styles.viewContainer, {
          [styles.scroll]: true,
        })}
      >
        <Flex direction="row" justify="between" className={styles.filtersContainer}>
          <Flex gap={12}>
            <WppInput
              size="s"
              name="search"
              placeholder={t('dashboard.field_search_placeholder')!}
              onWppChange={e => setSearchDebounced(e.detail.value || '')}
              type="search"
              data-testid="dashboard-project-search"
              className={styles.searchInput}
            />

            <TenantFilterSelect tenantIds={filter.tenant ?? []} onChange={handleWorkspaceFilterChange} />

            <WppSegmentedControl size="s" variant="icon" value={viewType}>
              <WppSegmentedControlItem variant="icon" value={ViewType.CARDS} data-testid="projects-card-view-button">
                <Link to={routesManager.projects.cards.pattern} className={styles.segmentLink}>
                  <WppIconDataViewCards />
                </Link>
              </WppSegmentedControlItem>

              <WppSegmentedControlItem variant="icon" value={ViewType.TABLE} data-testid="projects-table-view-button">
                <Link to={routesManager.projects.table.pattern} className={styles.segmentLink}>
                  <WppIconDataViewList />
                </Link>
              </WppSegmentedControlItem>
            </WppSegmentedControl>
            <WppFilterButton
              counter={filtersCounts}
              onClick={() =>
                showProjectFilterModal({
                  filter,
                  handleCloseModal: handleCloseFilterModal,
                })
              }
            >
              {t('dashboard.btn_filter')!}
            </WppFilterButton>
          </Flex>
          <Flex>
            {canCreateProject && (projectsExist || isFilterActive) && (
              <WppButton size="s" onClick={() => showCreateProjectModal()} data-testid="create-new-project">
                <WppIconPlus slot="icon-start" /> {t('dashboard.btn_add_project')}
              </WppButton>
            )}
          </Flex>
        </Flex>

        <Routes>
          <Route
            path="/"
            element={
              viewType === ViewType.CARDS ? (
                <Navigate to={routesManager.projects.cards.shortPattern} replace />
              ) : (
                <Navigate to={routesManager.projects.table.shortPattern} replace />
              )
            }
          />

          <Route
            path={routesManager.projects.cards.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.CARDS)}>
                <ProjectCardView filter={filter} setProjectsExist={setProjectsExist} />
              </OnRouteEnter>
            }
          />
          <Route
            path={routesManager.projects.table.shortPattern}
            element={
              <OnRouteEnter onEnter={() => setViewType(ViewType.TABLE)}>
                <ProjectsTableView filter={filter} setProjectsExist={setProjectsExist} />
              </OnRouteEnter>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Flex>
  )
}
