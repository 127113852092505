import {
  WppActionButton,
  WppIconMore,
  WppIconTrash,
  WppListItem,
  WppMenuGroup,
  WppMenuContext,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { PropsWithChildren, ReactNode, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteProjectApi } from 'api/projects/mutation/useDeleteProjectApi'
import { useUpdateProjectApi } from 'api/projects/mutation/useUpdateProjectApi'
import { showConfirmModal } from 'components/common/confirmModal/ConfirmModal'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useProjectRole } from 'hooks/useProjectRole'
import { useToast } from 'hooks/useToast'
import styles from 'pages/dashboard/components/projectManageMenu/ProjectManageMenu.module.scss'
import { StatusMenuItem } from 'pages/dashboard/components/projectManageMenu/StatusMenuItem'
import { queryClient } from 'providers/osQueryClient/utils'
import { Project, ProjectStatus } from 'types/projects/projects'

interface Props {
  project: Project
  securedChildren?: ReactNode
  manageStatus?: boolean
  onDelete?: () => void
}

export const ProjectManageMenu = ({
  project,
  securedChildren,
  manageStatus = false,
  onDelete,
  children,
}: PropsWithChildren<Props>) => {
  const { t } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: handleDeleteProject } = useDeleteProjectApi()
  const { mutateAsync: updateProject } = useUpdateProjectApi()
  const { isOwnerOrGlobalManage } = useProjectRole()

  const withStatus = manageStatus && isOwnerOrGlobalManage

  const handleDelete = async () => {
    try {
      await handleDeleteProject({ projectId: project.id })
      showToast({
        type: 'success',
        header: t('project.list.toast_success_title')!,
        message: t('project.list.toast_success_message'),
      })

      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_FETCHER])
      await queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_INFINITE])
      tableActions.reload([TableKey.PROJECT_LIST])

      onDelete?.()
    } catch (e) {
      console.error(e)
      showToast({
        type: 'error',
        header: t('project.list.toast_error_title')!,
        message: t('project.list.toast_error_message'),
      })
    }
  }
  const isWrikeConnected = useMemo(() => project.wrike?.isConnected, [project.wrike?.isConnected])

  const handleUpdateStatus = useCallback(
    (status: ProjectStatus) => async (updateAllTasks?: boolean) => {
      try {
        await updateProject({ id: project.id, project: { status, updateAllTasks } })

        await Promise.all([
          queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_BY_ID]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASKS_LIST]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECTS_DASHBOARD_DATA]),
          queryClient.invalidateQueries([ApiQueryKeys.PROJECT_INTEGRATION]),
        ])
      } catch (e) {
        console.error(e)
        showToast({
          type: 'error',
          message: t('project.page.toast.update_status_error'),
        })
      }
    },
    [project.id, showToast, updateProject, t],
  )

  if (!isOwnerOrGlobalManage && !children) return null

  return (
    <WppMenuContext
      key={project.processType}
      slot="actions"
      className={styles.rowContextMenu}
      data-testid="project-context-shortcut"
    >
      <WppActionButton slot="trigger-element" variant="secondary">
        <WppIconMore
          data-testid="project-shortcuts"
          slot="icon-start"
          direction="horizontal"
          color="var(--wpp-primary-color-500)"
        />
      </WppActionButton>
      <WppMenuGroup withDivider={withStatus}>
        <Flex direction="column" gap={4}>
          {children}

          {isOwnerOrGlobalManage && (
            <>
              {securedChildren}
              <WppListItem
                data-testid="delete-action"
                onWppChangeListItem={() =>
                  showDeleteModal({
                    title: `Delete ${project.name.length > 40 ? project.name.substring(0, 40) + '...' : project.name}?`,
                    subTitle: isWrikeConnected
                      ? t('modals.delete_project.delete_wrike_description')
                      : t('modals.delete_project.delete_description'),
                    onDelete: handleDelete,
                  })
                }
              >
                <WppIconTrash slot="left" />
                <WppTypography slot="label" type="s-body" data-testid="delete-project">
                  {t('project.list.btn_delete_project')}
                </WppTypography>
              </WppListItem>
            </>
          )}
        </Flex>
      </WppMenuGroup>

      {withStatus && isOwnerOrGlobalManage && (
        <WppMenuGroup header={t('project.status.title')}>
          <Flex direction="column" gap={4}>
            <StatusMenuItem
              onWppChangeListItem={() => {
                project.status === ProjectStatus.ARCHIVED || project.status === ProjectStatus.COMPLETED
                  ? showConfirmModal({
                      title: t('modals.reactivateProject.title'),
                      confirmMessage: t('modals.reactivateProject.description'),
                      btnSubmitText: t('modals.reactivateProject.submitButton'),
                      handleSubmit: handleUpdateStatus(ProjectStatus.TO_DO),
                    })
                  : handleUpdateStatus(ProjectStatus.TO_DO)()
              }}
              isChecked={project.status === ProjectStatus.TO_DO}
              data-testid={`project-status-${ProjectStatus.TO_DO}`}
            >
              {t('project.status.to_do')}
            </StatusMenuItem>
            <StatusMenuItem
              onWppChangeListItem={() =>
                project.status === ProjectStatus.ARCHIVED || project.status === ProjectStatus.COMPLETED
                  ? showConfirmModal({
                      title: t('modals.reactivateProject.title'),
                      confirmMessage: t('modals.reactivateProject.description'),
                      btnSubmitText: t('modals.reactivateProject.submitButton'),
                      handleSubmit: handleUpdateStatus(ProjectStatus.IN_PROGRESS),
                    })
                  : handleUpdateStatus(ProjectStatus.IN_PROGRESS)()
              }
              isChecked={project.status === ProjectStatus.IN_PROGRESS}
              data-testid={`project-status-${ProjectStatus.IN_PROGRESS}`}
            >
              {t('project.status.in_progress')}
            </StatusMenuItem>

            <StatusMenuItem
              onWppChangeListItem={() =>
                showConfirmModal({
                  title: t('modals.completeProject.title'),
                  confirmMessage: t(
                    isWrikeConnected
                      ? 'modals.completeProject.descriptionWrikeConnected'
                      : 'modals.completeProject.description',
                  ),
                  confirmSubMessage: !!project.uncompletedTasksCount
                    ? t('modals.completeProject.tasksUncompletedSubtext')
                    : undefined,
                  btnSubmitText: t('modals.completeProject.submitButton'),
                  withCheckbox: true,
                  checkboxLabel: t('modals.completeProject.checkbox'),
                  handleSubmit: handleUpdateStatus(ProjectStatus.COMPLETED),
                })
              }
              isChecked={project.status === ProjectStatus.COMPLETED}
              data-testid={`project-status-${ProjectStatus.COMPLETED}`}
            >
              {t('project.status.completed')}
            </StatusMenuItem>

            <StatusMenuItem
              onWppChangeListItem={() =>
                showConfirmModal({
                  title: t('modals.archiveProject.title'),
                  confirmMessage: t(
                    isWrikeConnected ? 'modals.archiveProject.wrikeDescription' : 'modals.archiveProject.description',
                  ),
                  btnSubmitText: t('modals.archiveProject.submitButton'),
                  handleSubmit: handleUpdateStatus(ProjectStatus.ARCHIVED),
                })
              }
              isChecked={project.status === ProjectStatus.ARCHIVED}
              data-testid={`project-status-${ProjectStatus.ARCHIVED}`}
            >
              {t('project.status.archived')}
            </StatusMenuItem>
          </Flex>
        </WppMenuGroup>
      )}
    </WppMenuContext>
  )
}
