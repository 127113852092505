import { WppButton, WppTypography, WppCheckbox } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  title: string
  btnSubmitText?: string
  confirmMessage?: string | ReactNode
  confirmSubMessage?: string
  withCheckbox?: boolean
  checkboxLabel?: string

  handleSubmit(isChecked: boolean): void
}

const ConfirmModal = ({
  isOpen,
  onClose,
  onCloseComplete,
  title,
  btnSubmitText,
  confirmMessage,
  withCheckbox = false,
  checkboxLabel = '',
  handleSubmit,
  confirmSubMessage,
}: Props) => {
  const { t } = useTranslation()
  const [isChecked, setIsChecked] = useToggle(true)

  const submit = () => {
    handleSubmit(isChecked)
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      size="s"
      data-testid="confirm-modal"
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
    >
      <WppTypography slot="header" type="xl-heading">
        {title}
      </WppTypography>
      <Flex slot="body" direction="column" gap={12}>
        <WppTypography type="s-body" tag="p">
          {confirmMessage}
        </WppTypography>
        {confirmSubMessage && (
          <WppTypography type="s-body" tag="p">
            {confirmSubMessage}
          </WppTypography>
        )}

        {withCheckbox && (
          <WppCheckbox
            checked={isChecked}
            labelConfig={{ text: checkboxLabel }}
            required
            onWppChange={setIsChecked}
            data-testid="confirm-modal-checkbox"
          />
        )}
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} size="s" variant="secondary">
          {t('common.btn_cancel')}
        </WppButton>
        <WppButton variant="primary" size="s" onClick={submit} data-testid="confirm-btn">
          {btnSubmitText ?? t('common.btn_confirm')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showConfirmModal } = createNiceModal<Props>(ConfirmModal, 'confirm-modal')
