export enum PAGE_ANALYTICS {
  PAGE_PROJECTS = 'page_projects',
  PAGE_PROJECT_CREATE = 'page_project_create',
}

export enum ACTION_ANALYTICS {
  ACTION_PAGE_PROJECTS = 'action_page_projects',
  ACTION_PROJECT_CREATE = 'action_project_create',
  APPLICATION_LAUNCH = 'app_launch',
}

export enum LAUNCH_LOCATIONS {
  NavigationMenu = 'Navigation Menu',
  Breadcrumbs = 'Breadcrumbs',
  ProjectWorkflow = 'Project Workflow',
  Favorites = 'Favorites',
  HamburgerMenu = 'Hamburger Menu',
}
