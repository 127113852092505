import { MayBeNull } from '@wpp-open/core'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { usePatchPhaseApi } from 'api/canvas/mutation/usePatchPhaseApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useToast } from 'hooks/useToast'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import { getDate, ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { updateMembersData } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { LinearData } from 'pages/project/components/canvas/utils'
import { queryClient } from 'providers/osQueryClient/utils'
import { PhaseStatus } from 'types/projects/workflow'

interface Props {
  phaseId: string
  projectId: string
}

interface WorkflowDataContext<T> {
  previousData?: { data: T }
}

export const useUpdatePhase = ({ phaseId, projectId }: Props) => {
  const { mutateAsync: handleUpdatePhase } = usePatchPhaseApi({
    onMutate: async newPhase => {
      const { phaseId, startDate, endDate, assignUser } = newPhase

      const projectTasksQueryKey = [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }]
      await queryClient.cancelQueries(projectTasksQueryKey)

      const previousData = queryClient.getQueryData<{ data: LinearData }>(projectTasksQueryKey)

      if (previousData) {
        const updatedData = {
          ...previousData.data,
          columns: {
            ...previousData.data.phases,
            [phaseId]: {
              ...previousData.data.phases[phaseId],
              startDate: startDate !== undefined ? startDate : previousData.data.phases[phaseId].startDate,
              endDate: endDate !== undefined ? endDate : previousData.data.phases[phaseId].endDate,
              assignUser: assignUser !== undefined ? assignUser : previousData.data.phases[phaseId].assignUser,
            },
          },
        }

        const newData = { ...previousData, data: updatedData }
        queryClient.setQueryData(projectTasksQueryKey, newData)
      }

      return { previousData }
    },
    onError: (_err, _, context) => {
      const typedContext: WorkflowDataContext<LinearData> = context || {}
      if (typedContext?.previousData) {
        queryClient.setQueryData([ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }], typedContext.previousData)
      }
    },
  })
  const { showToast } = useToast()
  const { t } = useTranslation()

  const updatePhase = async ({
    dates,
    assignUser,
    name,
    status,
  }: Partial<{
    dates: Date[]
    assignUser: MayBeNull<ResponsibleUser>
    name: string
    status: PhaseStatus
  }>) => {
    try {
      let body: Parameters<typeof handleUpdatePhase>[0] = { phaseId }

      if (dates) {
        const stringDates = dates.map(date => format(date, projectDateFormat))
        body = {
          ...body,
          ...getDate(stringDates),
        }
      }

      // `assignUser` is a nullable object, but we shouldn't erase it if this field is not presented in DTO
      if (assignUser !== void 0) {
        body = {
          ...body,
          assignUser: assignUser?.email || null,
        }
      }

      if (status) {
        body = {
          ...body,
          status,
        }
      }

      updateMembersData({ projectId: projectId!, assignUser })
      await handleUpdatePhase(body)
      await invalidateCanvas(!assignUser?.isMember)
      showToast({ type: 'success', message: t('project.canvas.toast.update_phase', { query: name }) })
    } catch (e) {
      showToast({
        type: 'error',
        message: t('project.canvas.toast.failed_operation_edit', { query: 'phase' }),
      })
      console.error(e)
    }
  }

  return { updatePhase }
}
