import clsx from 'clsx'
import { ViewMode, Gantt, Task as GanttTask } from 'gantt-task-react'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useEffectOnce, useUpdateEffect } from 'react-use'

import { TaskListHeader } from 'pages/project/components/timeline/components/taskListTable/TaskListHeader'
import { TaskListTable } from 'pages/project/components/timeline/components/taskListTable/TaskListTable'
import styles from 'pages/project/components/timeline/components/TimelineChart.module.scss'
import { TooltipContent } from 'pages/project/components/timeline/components/tooltipContent/TooltipContent'
import { useFocusToday } from 'pages/project/components/timeline/components/useFocusToday'
import 'gantt-task-react/dist/index.css'
import { DetailsModalType } from 'types/common/utils'

interface Props {
  tasks: GanttTask[]
}

export const TimelineChart = ({ tasks: initialTasks }: Props) => {
  const [tasks, setTasks] = useState(initialTasks)
  const [, setSearchParams] = useSearchParams()
  const focusToday = useFocusToday()

  useUpdateEffect(() => {
    const prevTasksCollapseMap = Object.fromEntries(tasks.map(task => [task.id, task.hideChildren]))

    // preserve collapsed state
    const newTasks = initialTasks.map(task => ({
      ...task,
      hideChildren: prevTasksCollapseMap[task.id] ?? task.hideChildren,
    }))
    setTasks(newTasks)
  }, [initialTasks])

  const handleExpanderClick = (selectedTask: GanttTask) => {
    setTasks(tasks.map(task => (task.id === selectedTask.id ? selectedTask : task)))
  }

  const handleClick = (task: GanttTask) => {
    if (task.type === 'project') {
      setSearchParams({ view: DetailsModalType.PHASE_DETAILS_PREVIEW, phaseId: task.id })
      return
    }

    setSearchParams({ view: DetailsModalType.TASK_DETAILS_PREVIEW, id: task.id })
  }

  useEffectOnce(() => {
    focusToday()
  })

  return (
    <div className={clsx(styles.ganttWrapper, 'ganttWrapper')}>
      <Gantt
        tasks={tasks}
        viewMode={ViewMode.Day}
        fontSize="12px"
        fontFamily="Inter"
        barCornerRadius={6}
        onClick={handleClick}
        onExpanderClick={handleExpanderClick}
        listCellWidth="258px"
        columnWidth={72}
        rowHeight={40}
        TooltipContent={TooltipContent}
        TaskListHeader={TaskListHeader}
        TaskListTable={TaskListTable}
      />
    </div>
  )
}
