import { WppFilterButton, WppInput } from '@platform-ui-kit/components-library-react'
import { SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useDebounceFn } from 'hooks/useDebounceFn'
import 'gantt-task-react/dist/index.css'
import { useProject } from 'hooks/useProject'
import { TaskStatusFilter } from 'pages/project/components/tasks/components/statusFilter/TaskStatusFilter'
import { showTasksFiltersModal } from 'pages/project/components/tasks/components/tasksFilters/TasksFiltersModal'
import { useCustomExternalStatuses } from 'pages/project/components/tasks/hooks/useCustomExternalStatuses'
import { allStatuses } from 'pages/project/components/tasks/utils'
import styles from 'pages/project/components/timeline/components/timelineActionBar/TimelineActionBar.module.scss'
import { TimelineFilters } from 'pages/project/components/timeline/utils'

interface Props {
  filters: TimelineFilters
  setFilters: (cb: SetStateAction<TimelineFilters>) => void
}

const SEARCH_LENGTH_THRESHOLD = 2
const SEARCH_DEBOUNCE_TIME = 300

export const TimelineActionBar = ({ setFilters, filters }: Props) => {
  const { t } = useTranslation()
  const { useExternalStatuses, wrikeWorkflows } = useProject()
  const { externalStatusesEntries, externalStatusesMapped } = useCustomExternalStatuses(wrikeWorkflows)

  const statusesOptions = useMemo(() => {
    return useExternalStatuses ? externalStatusesMapped : allStatuses
  }, [externalStatusesMapped, useExternalStatuses])

  const setSearchDebounced = useDebounceFn((search: string) => {
    const searchQuery = search.trim().length >= SEARCH_LENGTH_THRESHOLD ? search.trim() : undefined
    setFilters(filters => ({ ...filters, search: searchQuery }))
  }, SEARCH_DEBOUNCE_TIME)

  const handleCloseFilterModal = (newFilter: TimelineFilters) => {
    setFilters(filters => ({ ...filters, ...newFilter }))
  }

  return (
    <Flex gap={8}>
      <WppInput
        size="s"
        name="search"
        placeholder={t('dashboard.field_search_placeholder')!}
        onWppChange={e => setSearchDebounced(e.detail.value || '')}
        type="search"
        data-testid="timeline-search"
        className={styles.searchInput}
      />

      <TaskStatusFilter
        useExternalStatuses={useExternalStatuses}
        filters={filters}
        setFilters={setFilters}
        statusesOptions={statusesOptions}
        externalStatusesEntries={externalStatusesEntries}
        wrikeWorkflowsLoading={!wrikeWorkflows}
      />

      <WppFilterButton
        onClick={() =>
          showTasksFiltersModal({
            filters,
            onFiltersSave: handleCloseFilterModal,
          })
        }
        data-testid="tasks-filter-button"
      >
        {t('project.files.btn_filter')!}
      </WppFilterButton>
    </Flex>
  )
}
