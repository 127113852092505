// as gantt-task-react is not allowed to do anything like this and it's rely on a virtual scroll
// we must add additional manipulations based on internal structure of the library
export const useFocusToday = () => {
  return () => {
    setTimeout(() => {
      const currentDay = document.querySelector('.today')
      if (!currentDay) return

      currentDay.scrollIntoView({ inline: 'center' })

      // get virtual scroll position
      const scrollLeft = document.querySelector('.ganttWrapper > div:first-child > div > div:nth-child(2)')?.scrollLeft

      // update virtual scroll position
      document.querySelector('.ganttWrapper > div:first-child > div:nth-child(2)')?.scrollTo({
        left: scrollLeft,
      })
    }, 100)
  }
}
