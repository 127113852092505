// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lwgJt{margin-top:20px}.lwgJt>div>div:first-child{border-radius:8px;box-shadow:0 1px 5px 0 rgba(52,58,63,.1)}.lwgJt>div>div:first-child>div:nth-child(2){border-top-right-radius:8px;border-bottom-right-radius:8px}.lwgJt .rows>*{fill:var(--wpp-white-color) !important}.lwgJt .rowLines{display:none}.lwgJt .rowHover{fill:var(--wpp-grey-color-200) !important}`, "",{"version":3,"sources":["webpack://./src/pages/project/components/timeline/components/TimelineChart.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CAGA,2BACE,iBAAA,CACA,wCAAA,CAEA,4CACE,2BAAA,CACA,8BAAA,CAKF,eACE,sCAAA,CAIJ,iBACE,YAAA,CAGF,iBACE,yCAAA","sourcesContent":[".ganttWrapper {\n  margin-top: 20px;\n\n  // timeline w/o scrollbar\n  & > div > div:first-child {\n    border-radius: 8px;\n    box-shadow: 0 1px 5px 0 rgb(52 58 63 / 10%);\n\n    & > div:nth-child(2) {\n      border-top-right-radius: 8px;\n      border-bottom-right-radius: 8px;\n    }\n  }\n\n  :global(.rows) {\n    & > * {\n      fill: var(--wpp-white-color) !important;\n    }\n  }\n\n  :global(.rowLines) {\n    display: none;\n  }\n\n  :global(.rowHover) {\n    fill: var(--wpp-grey-color-200) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ganttWrapper": `lwgJt`
};
export default ___CSS_LOADER_EXPORT___;
